import {  fetchit } from 'lib'

import {
    API_PUZZLES
} from 'config/api'


export function createPuzzle(data, accessToken) {
    return fetchit(API_PUZZLES, data, 'POST', accessToken)
}

export function editPuzzle(data, puzzleId, accessToken) {
    return fetchit(API_PUZZLES+'/'+puzzleId, data, 'PATCH', accessToken)
}

export function getPuzzles({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_PUZZLES+'/?place='+placeId, null, 'GET', accessToken)
}

export function deletePuzzle(puzzleId, accessToken) {
    if(!puzzleId) return
    return fetchit(API_PUZZLES+'/'+puzzleId, null, 'DELETE', accessToken)
}
