import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row, Column } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, TextArea } from "components/Form";
import { Title } from "components/Typography";
import languageList from 'config/languageList'

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  languages,
}) {
  const { t } = useTranslation();
  const { register, reset, handleSubmit } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    onSubmit(data);
  };

  const alpha2ToLanguage = (alpha2)=> languageList.filter(l=>l.alpha2===alpha2)[0]['english']

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        {languages &&
          languages.map((language) => (
            <div key={language + "key"}>
              <Title>{alpha2ToLanguage(language)}</Title>
             


               <Row>
          <Input
            {...register(`dictionary.name.${language}`, { required: true })}
            placeholder={t("form.quiz.name")}
          />
        </Row>
        <Row>
          <TextArea
            {...register(`dictionary.description.${language}`)}
            placeholder={t("form.description")}
          />
        </Row>
        <Row>
          <Input {...register(`dictionary.question.${language}`)} placeholder={t("form.question")} />
        </Row>
        <Row>
          <Column>
            {" "}
            <Input {...register(`dictionary.answers1.${language}`)} placeholder={t("form.answers")+" "+1} />
          </Column>
          <Column>
            {" "}
            <Input {...register(`dictionary.answers2.${language}`)} placeholder={t("form.answers")+" "+2} />
          </Column>
        </Row>
        <Row>
          <Column>
            {" "}
            <Input {...register(`dictionary.answers3.${language}`)} placeholder={t("form.answers")+" "+3} />
          </Column>
          <Column>
            {" "}
            <Input {...register(`dictionary.answers4.${language}`)} placeholder={t("form.answers")+" "+4} />
          </Column>
        </Row>

            </div>
          ))}

        <Spacing bottom={"sm"} />

        <Spacing bottom={"lg"} />
        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
