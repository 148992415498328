import {  fetchit } from 'lib'

import {
    API_ENIGMAS
} from 'config/api'


export function createEnigma(data, accessToken) {
    return fetchit(API_ENIGMAS, data, 'POST', accessToken)
}

export function editEnigma(data, enigmaId, accessToken) {
    return fetchit(API_ENIGMAS+'/'+enigmaId, data, 'PATCH', accessToken)
}

export function getEnigmas({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_ENIGMAS+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteEnigma(enigmaId, accessToken) {
    if(!enigmaId) return
    return fetchit(API_ENIGMAS+'/'+enigmaId, null, 'DELETE', accessToken)
}
