export const orderOptions = /** Order Options */ [
  {
    value: 1,
    label: "Enigmas",
  },
  {
    value: 2,
    label: "Mission",
  },
];
