export const API_BASE_URL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:4040/v1/"
    : "https://urban-gamification.fly.dev/v1/";
export const UPLOAD_CDN = "https://concierge.fra1.cdn.digitaloceanspaces.com/";
export const DEFAULT_DOC_URL =
  "https://fra1.digitaloceanspaces.com/team.marrakech.io/1662056408707.jpg";
export const API_AUTH_LOGIN = API_BASE_URL + "auth/login";
export const API_AUTH_LOGOUT = API_BASE_URL + "auth/logout";
export const API_AUTH_REFRESH_TOKENS = API_BASE_URL + "auth/refresh-tokens";
export const API_AUTH_AUTHORIZE = API_BASE_URL + "auth/authorize";
export const API_HOTEL = API_BASE_URL + "location";
export const API_STAFF = API_HOTEL + "/staff";
export const API_DEPARTMENT = API_BASE_URL + "department";
export const API_UPLOAD = API_BASE_URL + "upload";
export const API_CATEGORY = API_BASE_URL + "category";
export const API_USERS = API_BASE_URL + "users";
export const API_DOCUMENTS = API_BASE_URL + "document";
export const API_AVATARS = API_BASE_URL + "avatar";
export const API_BADGES = API_BASE_URL + "badge";
export const API_ENIGMAS = API_BASE_URL + "enigma";
export const API_PUZZLES = API_BASE_URL + "puzzle";
export const API_QUIZZES = API_BASE_URL + "quiz";
export const API_MISSIONS = API_BASE_URL + "mission";
export const API_OBJECTS = API_BASE_URL + "object";
export const API_QUESTS = API_BASE_URL + "quest";
export const API_TRADERS = API_BASE_URL + "trader";
export const API_PRODUCTS = API_BASE_URL + "product";
