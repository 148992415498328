import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";

const GlobalStyle = createGlobalStyle` 
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {

    background-color: ${(p) => p.theme.colors.light.sidebar};
    
  }
  iframe{
    border:0;
    width: 100%;
    height: 550px ;
  }
  body {
    color: ${(p) => p.theme.colors.light.text};
    box-sizing: border-box;
    font-family: ${(props) => props.theme.font.body};
    background-color: ${(props) => props.theme.colors.light.sidebar};
    overflow-x: hidden ;
    margin: 0;
    padding: 0;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  label {
    color: rgba(0,0,0,.75);
    font-family: ${(props) => props.theme.font.title};
    :hover{
      color: ${(p) => p.theme.colors.light.text};
    }
  }

  nav, ul {
    margin: 0;
    padding: 0 ;
  }
  h1, h2, h3, h4, h5 {
    font-family: ${(props) => props.theme.font.title};
  }
  .react-tabs__tab{
    position: inherit !important ;
    border-radius:0
  }
  .react-tabs__tab--selected{
  border-color: ${(props) => props.theme.colors.light.border};
  background-color:  ${(props) => props.theme.colors.light.sidebar};
  font-weight:800;
  color:white
}

  select {
    /*-webkit-appearance: none;
  -moz-appearance: none;*/
  text-indent: 1px;
  text-overflow: '';

}



.react-toggle-track-x{
  right: 17px !important ;
}

.react-tabs__tab-list{
  border-bottom: 1px solid #f0f0f0;
}
.filepond--root .filepond--drop-label{
  background-color: ${(props) => props.theme.colors.light.sidebar};
  color: ${(props) => props.theme.colors.light.contrastedText};
  border:2px solid #000000;
  label {
    color: ${(props) => props.theme.colors.light.contrastedText};
  }

}

.filepond--item{
    top:20px;
  }

  text{
    font-weight:900 ;
  }
  @keyframes slideIn {
   0% {
      left: 100%;
      opacity: 0;
     
   }
   100% {
      left: 0;
      opacity:1;
   }
}

.rdp table{
  max-width: 100%;
  min-width: 100%;
  margin-top:50px;
  font-family: ${(props) => props.theme.font.body};

}

.rdp-months{
  display:block ;
  font-family: ${(props) => props.theme.font.title};
}
.rdp-day{
  transition: .3s all ;
}
.rdp-head_cell{
  text-align: left;
  padding-left:10px;
  font-family: ${(props) => props.theme.font.title};
}

.rdp-day_selected{
  background-color: #ffc107;
  border: 1px solid #ffc107;
  font-weight: 900;
}
.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover{
  background-color: #ffc107
}



@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

`;

export default GlobalStyle;
