import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "components/ProtectedRoute";
import AuthRoutes, { path as authPath } from "./auth";
import NotFoundRoutes, { path as notFoundPath } from "./404";
import PublicRoutes, { path as publicPath } from "./public";
//🔒 protected routes
import LocationsRoutes, { path as locationsPath } from "./locations";
import DepartmentsRoutes, { path as departmentPath } from "./departments";
import MediaRoutes, { path as mediaPath } from "./media";
import CategoryRoutes, { path as categoryPath } from "./categories";
import DocumentRoutes, { path as documentPath } from "./documents";
import AvatarRoutes, { path as avatarPath } from "./avatars";
import BadgeRoutes, { path as badgePath } from "./badges";
import EnigmaRoutes, { path as enigmaPath } from "./enigma";
import PuzzleRoutes, { path as puzzlePath } from "./puzzles";
import QuizRoutes, { path as quizPath } from "./quiz";
import MissionRoutes, { path as missionPath } from "./missions";
import ObjectRoutes, { path as objectPath } from "./objects";
import QuestRoutes, { path as QuestPath } from "./quests";
import TradersRoutes, { path as TradersPath } from "./traders";
import ProductsRoutes, { path as ProductsPath } from "./product";

export default function RoutesComponent() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path={authPath} element={<AuthRoutes />} />
            <Route exact path={publicPath} element={<PublicRoutes />} />
            <Route element={<ProtectedRoute />}>
              {/**🔒 Protected routes */}
              <Route exact path={locationsPath} element={<LocationsRoutes />} />
              <Route
                exact
                path={departmentPath}
                element={<DepartmentsRoutes />}
              />
              <Route exact path={documentPath} element={<DocumentRoutes />} />
              <Route exact path={mediaPath} element={<MediaRoutes />} />
              <Route exact path={categoryPath} element={<CategoryRoutes />} />
              <Route exact path={avatarPath} element={<AvatarRoutes />} />
              <Route exact path={badgePath} element={<BadgeRoutes />} />
              <Route exact path={enigmaPath} element={<EnigmaRoutes />} />
              <Route exact path={puzzlePath} element={<PuzzleRoutes />} />
              <Route exact path={quizPath} element={<QuizRoutes />} />
              <Route exact path={missionPath} element={<MissionRoutes />} />
              <Route exact path={objectPath} element={<ObjectRoutes />} />
              <Route exact path={QuestPath} element={<QuestRoutes />} />
              <Route exact path={TradersPath} element={<TradersRoutes />} />
              <Route exact path={ProductsPath} element={<ProductsRoutes />} />
              {/**🔒 End of Protected routes */}
            </Route>
            <Route path={notFoundPath} element={<NotFoundRoutes />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </>
  );
}
