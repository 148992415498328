import {  fetchit } from 'lib'

import {
    API_BADGES
} from 'config/api'

export function createBadge(data, accessToken) {
    return fetchit(API_BADGES, data, 'POST', accessToken)
}

export function editBadge(data, avatarId, accessToken) {
    return fetchit(API_BADGES+'/'+avatarId, data, 'PATCH', accessToken)
}

export function getBadges({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_BADGES+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteBadge(avatarId, accessToken) {
    if(!avatarId) return
    return fetchit(API_BADGES+'/'+avatarId, null, 'DELETE', accessToken)
}
