import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row, Column } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, TextArea, Select } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  tradersList
}) {
  const { t } = useTranslation();
  const [media, setMedia] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  /** products Options */
  const traderOptions = tradersList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;

    delete data.tags;
    onSubmit({ ...data, media: mediaIds });
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  useEffect(() => {
    if (!!defaultValues?.media) {
      setMedia(defaultValues.media);
    }
  }, [defaultValues]);

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Spacing bottom={"lg"} />
        <Row>
          <Select {...register("trader")} small>
            <option value={null} disabled>
              {t("trader")}
            </option>
            {traderOptions.map((item, k) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Row>
        <Spacing bottom={"sm"} />
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.product.name")}
          />
          {errors.name && <Error>Product Name is required</Error>}
        </Row>
        <Spacing bottom={"sm"} />
        <Row>
          <TextArea
            {...register("description")}
            placeholder={t("form.product.description")}
          />
        </Row>
        <Spacing bottom={"sm"} />
        <Row>
          <Column>
            <Input {...register("coins")} placeholder={t("form.coins")} />
          </Column>
          {errors.coins && <Error>Coins is required</Error>}
          <Spacing bottom={"sm"} />
          <Column>
            <Input
              {...register("reduction")}
              placeholder={t("form.product.reduction")}
            />
          </Column>
        </Row>

        <Spacing bottom={"lg"} />
        <label>Media </label>
        <ModalGallery isMany onClick={handleGallery} />
        <Gallery medias={media} small />
        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
