import styled from "styled-components";
import ReactSelect from 'react-select'

export const Badge = styled.div`
position: absolute ;
right:5px ;
top:10px;
z-index:99 ;
width: 26px;
height: 26px;
background-color: red ;
text-align: center ;
color: white ;
background-color:#2196f3 ;
padding:1px ;
`

export const Button = styled.button`
  font-family: ${(props) => props.theme.font.body};
  width:${props=> props.width ? props.width : "100%"} ;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  border: none;
  padding: 0 56px;
  height: ${props=> props.height ? props.height : "45px"} ;
  line-height:${props=> props.height ? props.height : "45px"} ;
  background-color:${props=> props.secondary ? '#161616' : "#0070f3"} ; 
  color: white;
  font-weight: 100;
  font-size: ${props=> props.fontSize ? props.fontSize : "16px"};
  box-shadow: 0 4px 14px 0 rgb(0 118 255 / 39%);
  transition: background 0.2s ease,color 0.2s ease,box-shadow 0.2s ease;
  :hover{
      background: rgba(0,118,255,0.9);
      box-shadow: 0 6px 20px rgb(0 118 255 / 23%);
  }
  :active {
    transform: scale(.99) ;
  }
`;

export const MultiSelect = styled(ReactSelect)`
  font-family: ${(props) => props.theme.font.body};;
  font-size: ${(props) => props.theme.font.size.md};
  background-color: white ;
  font-weight:100;
  padding: 10px;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.xs};
  cursor: pointer;
  box-shadow: ${(p) => p.theme.shadows.sm};
  border:none ;
  :focus-visible {
    outline: transparent;
    border: none;
    border-bottom: 2px dashed ${(p) => p.theme.colors.light.border};
  }
`


export const Input = styled.input`
  font-family: ${(props) => props.theme.font.body};
  background-color: white ;
  font-size: ${(props) => props.theme.font.size.sm};
  font-weight:100;
  padding: 10px;
  width: 100%;
  text-align: center;
  box-shadow: ${(p) => p.theme.shadows.sm};
  margin-bottom: ${(props) => props.theme.spacing.xs};
  cursor: pointer;
  border: 2px solid rgba(0,0,0,.05);



  color: ${(props) => props.theme.colors.light.text};
  :disabled {
  background: #dddddd;
  cursor: no-drop ;
}
  :focus-visible {
    outline: transparent;
    border: none;
    border: 2px dashed ${(p) => p.theme.colors.light.border};
  }
`;

export const TextArea = styled.textarea`
  font-family: ${(props) => props.theme.font.body};
  background-color: white ;
  font-size: ${(props) => props.theme.font.size.sm};
  font-weight:100;
  padding: 10px;
  width: 100%;
  text-align: center;
  box-shadow: ${(p) => p.theme.shadows.sm};
  margin-bottom: ${(props) => props.theme.spacing.xs};
  cursor: pointer;
  border: 2px solid rgba(0,0,0,.05);



  color: ${(props) => props.theme.colors.light.text};
  :focus-visible {
    outline: transparent;
    border: none;
    border: 2px dashed ${(p) => p.theme.colors.light.border};
  }
`;

export const Select = styled.select`
position: relative ;
  font-family: ${(props) => props.small ? props.theme.font.body : props.theme.font.title};
  font-size: ${(props) => props.small ? props.theme.font.size.sm : props.theme.font.size.md};
  padding: 10px;
  text-transform:  capitalize;
  box-shadow: ${(props) => props.small ? props.theme.shadows.sm : 'inset 1px 8px 6px 1px rgb(0 0 0 / 12%), 0 1px 4px rgb(0 0 0 / 24%)'}; 
  width: 100% ;
  text-align: center;
  border: none;
  border:0;
  margin:0;
  color: ${(p) => p.theme.colors.light.text};
  cursor: pointer;

  option {
    color:  ${(p) => p.theme.colors.light.text};
    border:0
  }

  :focus-visible {
    outline: transparent;
  }
`;

export const Error = styled.p`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.colors.danger};
  text-align: center ;
  opacity: .7 ;
`;
