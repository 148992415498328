import {  fetchit } from 'lib'

import {
    API_QUIZZES
} from 'config/api'


export function createQuiz(data, accessToken) {
    return fetchit(API_QUIZZES, data, 'POST', accessToken)
}

export function editQuiz(data, enigmaId, accessToken) {
    return fetchit(API_QUIZZES+'/'+enigmaId, data, 'PATCH', accessToken)
}

export function getQuizzes({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_QUIZZES+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteQuiz(enigmaId, accessToken) {
    if(!enigmaId) return
    return fetchit(API_QUIZZES+'/'+enigmaId, null, 'DELETE', accessToken)
}
