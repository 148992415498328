/** Mission level */
export const missionLevelOptions = ["easy", "medium", "hard"];

export const orderOptions = /** Order Options */ [
  {
    value: 1,
    label: "Quiz",
  },
  {
    value: 2,
    label: "Puzzle",
  },
  {
    value: 3,
    label: "Object",
  },
];
