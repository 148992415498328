import {  fetchit } from 'lib'

import {
    API_OBJECTS
} from 'config/api'


export function createObject(data, accessToken) {
    return fetchit(API_OBJECTS, data, 'POST', accessToken)
}

export function editObject(data, puzzleId, accessToken) {
    return fetchit(API_OBJECTS+'/'+puzzleId, data, 'PATCH', accessToken)
}

export function getObjects({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_OBJECTS+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteObject(puzzleId, accessToken) {
    if(!puzzleId) return
    return fetchit(API_OBJECTS+'/'+puzzleId, null, 'DELETE', accessToken)
}
