import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { omit } from "lodash-es";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import { getQuizzes, deleteQuiz, createQuiz, editQuiz } from "./services";
import { getHotelById } from "../locations/services";
import removeNil from "lib/removeNil";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import YoutubeEmbed from "components/YoutubeEmbed";
import Form from "./Form";
import DictionaryForm from "./DictionaryForm";
import List from "components/List";
import { Button } from "components/Form";
import { FixedBottom } from "components/Layout/Styles";
import { GET_QUIZZES } from "config/queries";

export default function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const [dictionaryVisible, setDictionaryVisible] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const quizList = useQuery(GET_QUIZZES + selectedLocation, () =>
    getQuizzes({
      placeId: selectedLocation,
      accessToken: access.token,
    })
  );

  const selectedLocationDetails = useQuery(selectedLocation, () =>
    getHotelById({
      hotelId: selectedLocation,
      accessToken: access.token,
    })
  );

  const onNewMutation = useMutation(
    (data) => createQuiz(data, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_QUIZZES + selectedLocation);
        setResetForm(true);
        setNewOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onEditMutation = useMutation(
    (data) => editQuiz(data, currentQuiz._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_QUIZZES + selectedLocation);
        setResetForm(true);
        setOverlayVisible(false);
        setDictionaryVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onDeleteMutation = useMutation(
    (quizId) => deleteQuiz(quizId, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_QUIZZES + selectedLocation);
      },
      onError: (err) => {
        toast.error(t("update.error"));
        console.error(err);
      },
    }
  );

  const onNew = async (data) => {
    let dataCopy = { ...data };
    onNewMutation.mutate({
      ...removeNil(dataCopy),
      place: selectedLocation,
    });
    setTabIndex(0);
  };

  const onEdit = async (data) => {
    const cleanData = omit(data, ["place", "createdBy", "slug"]);

    let dataCopy = { ...cleanData };
    onEditMutation.mutate({
      ...removeNil(dataCopy),
    });
  };

  const onDelete = (quizId) => {
    onDeleteMutation.mutate(quizId);
  };

  const onListClick = (item) => {
    setOverlayVisible(true);
    setCurrentQuiz({
      ...item
    });
  };

  const onDictionaryClick = (item) => {
    setDictionaryVisible(true);
    setCurrentQuiz({
      ...item,
      dictionary: item.dictionary,
    });
  };

  let tabs = [];

  if (quizList?.data?.results && quizList.data.results[0]) {
    tabs.push({
      title: "🏢 " + t("quizzes"),
      component: (
        <List
          onDictionaryClick={onDictionaryClick}
          items={quizList?.data?.results}
          onDeleteBtn={onDelete}
          onClick={onListClick}
        />
      ),
    });
  }

  tabs.push({
    title: t("help"),
    component: <YoutubeEmbed embedId="oznr-1-poSU" />,
  });

  return (
    <Layout>
   
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}

        <FixedBottom>
          <Button onClick={() => setNewOverlayVisible(true)}>
            {t("form.add")} {t("quiz")}
          </Button>
        </FixedBottom>
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          defaultValues={currentQuiz}
          quizList={quizList?.data?.results}
        />
      </Modal>
      <Modal visible={newOverlayVisible} setVisible={setNewOverlayVisible}>
        <Form
          onSubmit={onNew}
          buttonLabel={t("form.save")}
          resetForm={resetForm}
          quizList={quizList?.data?.results}
        />
      </Modal>

      <Modal visible={dictionaryVisible} setVisible={setDictionaryVisible}>
        <DictionaryForm
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          languages={selectedLocationDetails?.data?.locales}
          defaultValues={currentQuiz}
        />
      </Modal>
    </Layout>
  );
}
