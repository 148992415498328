import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row, Column } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, TextArea } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const [media, setMedia] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;

    delete data.tags;
    onSubmit({ ...data, media: mediaIds });
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  useEffect(() => {
    if (!!defaultValues?.media) {
      setMedia(defaultValues.media);
    }
  }, [defaultValues]);

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Spacing bottom={"lg"} />
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.trader.name")}
          />
          {errors.name && <Error>Trader Name is required</Error>}
        </Row>
        <Spacing bottom={"sm"} />

        <Input
          {...register("whatsappPhone")}
          placeholder={t("from.trader.whatsappPhone")}
        />

        <Spacing bottom={"sm"} />
        <Row>
          <Column>
            <Input
              {...register("whatsappPhone2")}
              placeholder={t("from.trader.whatsappPhone") + " 2"}
            />
          </Column>
          <Column>
            <Input
              {...register("whatsappPhone3")}
              placeholder={t("from.trader.whatsappPhone") + " 3"}
            />
          </Column>
        </Row>

        <Spacing bottom={"sm"} />
        <Row>
          <Column>
            <Input {...register("latitude")} placeholder={t("form.latitude")} />
          </Column>
          <Column>
            <Input
              {...register("longitude")}
              placeholder={t("form.longitude")}
            />
          </Column>
        </Row>
        <Spacing bottom={"lg"} />

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
