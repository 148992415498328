import {  fetchit } from 'lib'

import {
    API_AVATARS
} from 'config/api'


export function createAvatar(data, accessToken) {
    return fetchit(API_AVATARS, data, 'POST', accessToken)
}

export function editAvatar(data, avatarId, accessToken) {
    return fetchit(API_AVATARS+'/'+avatarId, data, 'PATCH', accessToken)
}

export function getAvatars({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_AVATARS+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteAvatar(avatarId, accessToken) {
    if(!avatarId) return
    return fetchit(API_AVATARS+'/'+avatarId, null, 'DELETE', accessToken)
}
