import { fetchit } from "lib";

import { API_TRADERS } from "config/api";

export function createTrader(data, accessToken) {
  return fetchit(API_TRADERS, data, "POST", accessToken);
}

export function updateTrader(data, traderId, accessToken) {
  return fetchit(API_TRADERS + "/" + traderId, data, "PATCH", accessToken);
}

export function getTraders({ placeId, accessToken }) {
  if (!placeId) return;
  return fetchit(API_TRADERS + "/?place=" + placeId, null, "GET", accessToken);
}

export function deleteTrader(traderId, accessToken) {
  if (!traderId) return;
  return fetchit(API_TRADERS + "/" + traderId, null, "DELETE", accessToken);
}
