import { fetchit } from "lib";

import { API_PRODUCTS } from "config/api";

export function createProduct(data, accessToken) {
  return fetchit(API_PRODUCTS, data, "POST", accessToken);
}

export function updateProduct(data, productId, accessToken) {
  return fetchit(API_PRODUCTS + "/" + productId, data, "PATCH", accessToken);
}

export function getProducts({ placeId, accessToken }) {
  if (!placeId) return;
  return fetchit(API_PRODUCTS + "/?place=" + placeId, null, "GET", accessToken);
}

export function deleteProduct(productId, accessToken) {
  if (!productId) return;
  return fetchit(API_PRODUCTS + "/" + productId, null, "DELETE", accessToken);
}
