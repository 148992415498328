import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Error,
  TextArea,
  MultiSelect,
  Select,
} from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";
import { missionLevelOptions } from "config/missions";
import { orderOptions } from "config/quest";
import Toggle from "react-toggle";

export default function Form({
  onSubmit,
  defaultValues,
  missionList,
  badgeList,
  buttonLabel,
  resetForm,
  enigmasList,
}) {
  const { t } = useTranslation();
  const [media, setMedia] = useState([]);
  const [visiblityCheck, setVisiblityCheck] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  /** missions Options */
  const missionOptions = missionList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (Array.isArray(defaultValues?.missions)) {
      const defaultMissions = missionList
        .filter((l) => defaultValues.missions.indexOf(l._id) > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);
      // sort defaultMissions with defaultValues?.missions order
      const sortedDefaultMissions = defaultValues?.missions.map((id) => {
        return defaultMissions.filter((m) => m.value === id)[0];
      });
      setValue("missions", sortedDefaultMissions);
    }
  }, [defaultValues, missionList, setValue]);

  /** starter mission */
  useEffect(() => {
    if (defaultValues?.starterMission) {
      const defaultStarterMission = missionList
        .filter((l) => defaultValues.starterMission === l._id > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);

      setVisiblityCheck(true);
      setValue("starterMission", defaultStarterMission[0]);
    }
  }, [defaultValues, missionList, setValue]);

  /** enigmas Options */
  const enigmasOptions = enigmasList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (Array.isArray(defaultValues?.enigmas)) {
      const defaultEnigmas = enigmasList
        .filter((l) => defaultValues.enigmas.indexOf(l._id) > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("enigmas", defaultEnigmas);
    }
  }, [defaultValues, enigmasList, setValue]);

  /** Badge Options */
  const badgeOptions = badgeList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (Array.isArray(defaultValues?.badges)) {
      const defaultAvatar = badgeList
        .filter((l) => defaultValues.badges.indexOf(l._id) > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("badges", defaultAvatar);
    }
  }, [defaultValues, badgeList, setValue]);

  // Quest order
  useEffect(() => {
    const orderData = defaultValues?.order?.split(",");
    if (Array.isArray(orderData)) {
      // get order options from orderData by index orderData
      const orderOptionsData = orderData.map((od) => {
        return orderOptions[od - 1];
      });
      setValue("order", orderOptionsData);
    }
  }, [defaultValues, setValue]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;
    const missions = data?.missions ? data.missions.map((s) => s.value) : [];
    const enigmas = data?.enigmas ? data.enigmas.map((s) => s.value) : [];
    const order = data?.order ? data.order.map((s) => s.value) : [];
    const badges = data?.badges ? data.badges.map((s) => s.value) : [];

    onSubmit({
      ...data,
      media: mediaIds,
      missions,
      enigmas,
      order: order.toString(),
      badges,
    });
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  useEffect(() => {
    if (!!defaultValues?.media) {
      setMedia(defaultValues.media);
    }
  }, [defaultValues]);

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Spacing bottom={"lg"} />
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.quest.name")}
          />
          {errors.name && <Error>Mission Name is required</Error>}
        </Row>
        <Row>
          <TextArea
            {...register("description")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.description")}
          />
        </Row>
        <Spacing bottom={"sm"} />
        <Row>
          <Toggle
            value={visiblityCheck.toString()}
            checked={visiblityCheck}
            id="enable"
            name="enable"
            onChange={() => setVisiblityCheck(!visiblityCheck)}
          />
          <label htmlFor="visible">{t("form.enable")}</label>
        </Row>
        <Spacing bottom={"sm"} />
        <Row>
          <Select
            {...register("starterMission")}
            small
            disabled={!visiblityCheck}
          >
            <option value={null} disabled>
              {t("form.starterMission")}
            </option>
            {visiblityCheck &&
              missionOptions.map((item, k) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
          </Select>
        </Row>
        <Spacing bottom={"sm"} />
        {missionOptions && (
          <Controller
            name="missions"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={missionOptions}
                placeholder={t("missions")}
                {...field}
              />
            )}
          />
        )}
        <Spacing bottom={"sm"} />
        {enigmasOptions && (
          <Controller
            name="enigmas"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={enigmasOptions}
                placeholder={t("enigmas")}
                {...field}
              />
            )}
          />
        )}
        <Spacing bottom={"sm"} />
        {badgeOptions && (
          <Controller
            name="badges"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={badgeOptions}
                placeholder={t("badges")}
                {...field}
              />
            )}
          />
        )}

        <Spacing bottom="sm" />
        <Spacing bottom={"sm"} />
        {orderOptions && (
          <Controller
            name="order"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={orderOptions}
                placeholder={t("questorder")}
                {...field}
              />
            )}
          />
        )}
        <Row>
          <Select {...register("level")} small>
            <option value={null} disabled>
              {t("form.level")}
            </option>
            {missionLevelOptions.map((c, k) => (
              <option value={c} key={c + k}>
                {t(c)}
              </option>
            ))}
          </Select>
        </Row>
        <Spacing bottom="sm" />

        <Spacing bottom={"lg"} />
        <label>Media </label>
        <ModalGallery isMany onClick={handleGallery} />
        <Gallery medias={media} small />

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
