import PropTypes from "prop-types";
import NavigationComponent from "components/Navigation";
import LangSwitcher from "components/LangSwitcher";
import {
  MenuSideBar,
  Content,
  Container,
  Wrapper,

} from "./Styles";
import ListLocations from "../../routes/locations/List";
import  Logo  from "./Logo";
import NavigationItems from "generated/menuItems.json";

const Layout = ({
  hide = false,
  children,
  hideLeft = false,
  hideRight = false,
}) => {
  return (
    <>
       
      <Wrapper>
      
        {
          !hide && (
            <Container>
              {!hideLeft ? (
                <MenuSideBar>
                 <Logo />
                  <NavigationComponent items={NavigationItems} />
                  <LangSwitcher />
                </MenuSideBar>
              ) : null}

              <Content fullscreen={hideLeft || hideRight }>
                <ListLocations />
                {children}
              </Content>
          
            </Container>
          )
        }
      </Wrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
  hideLeft: PropTypes.bool,
  hideRight: PropTypes.bool,
  RightContent: PropTypes.element,
  LeftContent: PropTypes.element,
};

export default Layout;
