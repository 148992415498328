import { Player } from "@lottiefiles/react-lottie-player";

export default function Logo() {
  return (
    <Player
      autoplay
      loop
      src="https://assets6.lottiefiles.com/private_files/lf30_ysi7tprv.json"
      style={{ height: "100px", width: "100px" }}
    />
  );
}
