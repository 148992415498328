import {  fetchit } from 'lib'

import {
    API_MISSIONS
} from 'config/api'


export function createMission(data, accessToken) {
    return fetchit(API_MISSIONS, data, 'POST', accessToken)
}

export function editMission(data, missionId, accessToken) {
    return fetchit(API_MISSIONS+'/'+missionId, data, 'PATCH', accessToken)
}

export function getMissions({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_MISSIONS+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteMission(missionId, accessToken) {
    if(!missionId) return
    return fetchit(API_MISSIONS+'/'+missionId, null, 'DELETE', accessToken)
}
