import {  fetchit } from 'lib'

import {
    API_QUESTS
} from 'config/api'


export function createQuest(data, accessToken) {
    return fetchit(API_QUESTS, data, 'POST', accessToken)
}

export function editQuest(data, questId, accessToken) {
    return fetchit(API_QUESTS+'/'+questId, data, 'PATCH', accessToken)
}

export function getQuests({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_QUESTS+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteQuest(questId, accessToken) {
    if(!questId) return
    return fetchit(API_QUESTS+'/'+questId, null, 'DELETE', accessToken)
}
