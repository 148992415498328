import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Column, Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Error,
  TextArea,
  MultiSelect,
  Select,
} from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";
import Toggle from "react-toggle";
import { missionLevelOptions, orderOptions } from "config/missions";

export default function Form({
  onSubmit,
  defaultValues,
  quizList,
  avatarList,
  puzzleList,
  objectList,
  badgeList,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const [media, setMedia] = useState([]);
  const [visiblityCheck, setVisiblityCheck] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      defaultValues?.visible && setVisiblityCheck(defaultValues.visible);
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  /** Quiz Options */
  const quizOptions = quizList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (Array.isArray(defaultValues?.quiz)) {
      const defaultQuizz = quizList
        .filter((l) => defaultValues.quiz.indexOf(l._id) > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("quiz", defaultQuizz);
    }
  }, [defaultValues, quizList, setValue]);

  /** Avatar Options */
  const avatarOptions = avatarList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (Array.isArray(defaultValues?.avatar)) {
      const defaultAvatar = avatarList
        .filter((l) => defaultValues.avatar.indexOf(l._id) > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("avatar", defaultAvatar);
    }
  }, [defaultValues, avatarList, setValue]);

  /** Puzzle Options */
  const puzzleOptions = puzzleList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (Array.isArray(defaultValues?.puzzle)) {
      const defaultAvatar = puzzleList
        .filter((l) => defaultValues.puzzle.indexOf(l._id) > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("puzzle", defaultAvatar);
    }
  }, [defaultValues, puzzleList, setValue]);

  /** Badge Options */
  const badgeOptions = badgeList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (Array.isArray(defaultValues?.badge)) {
      const defaultAvatar = badgeList
        .filter((l) => defaultValues.badge.indexOf(l._id) > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("badge", defaultAvatar);
    }
  }, [defaultValues, badgeList, setValue]);

  /** Object Options */
  const objectOptions = objectList.map((qz) => {
    return { value: qz._id, label: qz.name };
  });

  useEffect(() => {
    if (Array.isArray(defaultValues?.badge)) {
      const defaultAvatar = objectList
        .filter((l) => defaultValues.object.indexOf(l._id) > -1)
        .map((r) => {
          return { label: r.name, value: r._id };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("object", defaultAvatar);
    }
  }, [defaultValues, objectList, setValue]);

 



  useEffect(() => {
    const orderData = defaultValues?.order?.split(",");
    if (Array.isArray(orderData)) {
      // get order options from orderData by index orderData
      const orderOptionsData = orderData.map((od) => {
        return orderOptions[od - 1];
      });
      setValue("order", orderOptionsData);
    }
  }, [defaultValues, setValue]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;
    const quiz = data?.quiz ? data.quiz.map((s) => s.value) : [];
    const avatar = data?.avatar ? data.avatar.map((s) => s.value) : [];
    const badge = data?.badge ? data.badge.map((s) => s.value) : [];
    const puzzle = data?.puzzle ? data.puzzle.map((s) => s.value) : [];
    const object = data?.object ? data.object.map((s) => s.value) : [];
    const order = data?.order ? data.order.map((s) => s.value) : [];

    onSubmit({
      ...data,
      media: mediaIds,
      quiz,
      avatar,
      badge,
      puzzle,
      object,
      order: order.toString(),
      visible: visiblityCheck,
    });
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  useEffect(() => {
    if (!!defaultValues?.media) {
      setMedia(defaultValues.media);
    }
  }, [defaultValues]);

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Spacing bottom={"lg"} />
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.mission.name")}
          />
          {errors.name && <Error>Mission Name is required</Error>}
        </Row>
        <Row>
          <TextArea
            {...register("description")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.description")}
          />
        </Row>
        <Row>
          <TextArea
            {...register("startDialogue")}
            placeholder={
              "Start: Dialogues séparés par une pipe. Exemple: dialogue1 | dialogue2..."
            }
          />
        </Row>
        <Row>
          <TextArea
            {...register("endDialogue")}
            placeholder={
              "End: Dialogues séparés par une pipe. Exemple: dialogue1 | dialogue2..."
            }
          />
        </Row>
        <Row>
          <TextArea
            {...register("indice")}
            placeholder={
              "indice séparés par une virgule. Exemple: indice1|points, indice2|nbr_point, ..."
            }
          />
        </Row>
        <Spacing bottom={"sm"} />
        {quizOptions && (
          <Controller
            name="quiz"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={quizOptions}
                placeholder={t("quizes")}
                {...field}
              />
            )}
          />
        )}
        <Spacing bottom={"sm"} />
        {avatarOptions && (
          <Controller
            name="avatar"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={avatarOptions}
                placeholder={t("avatars")}
                {...field}
              />
            )}
          />
        )}
        <Spacing bottom={"sm"} />
        {badgeOptions && (
          <Controller
            name="badge"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={badgeOptions}
                placeholder={t("badges")}
                {...field}
              />
            )}
          />
        )}
        <Spacing bottom={"sm"} />
        {puzzleOptions && (
          <Controller
            name="puzzle"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={puzzleOptions}
                placeholder={t("puzzles")}
                {...field}
              />
            )}
          />
        )}
        <Spacing bottom={"sm"} />
        {objectOptions && (
          <Controller
            name="object"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={objectOptions}
                placeholder={t("objects")}
                {...field}
              />
            )}
          />
        )}
        <Spacing bottom={"sm"} />
        {orderOptions && (
          <Controller
            name="order"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={orderOptions}
                placeholder={t("missionorder")}
                {...field}
              />
            )}
          />
        )}
        <Row>
          <Column>
            <Input {...register("coins")} placeholder={t("form.coins")} />
          </Column>
          <Column>
            <Input {...register("missionTime")} placeholder={t("form.time")} />
          </Column>
        </Row>
        <Spacing bottom="sm" />
        <Row>
          <Select {...register("level")} small>
            <option value={null} disabled>
              {t("form.level")}
            </option>
            {missionLevelOptions.map((c, k) => (
              <option value={c} key={c + k}>
                {t(c)}
              </option>
            ))}
          </Select>
        </Row>
        <Spacing bottom="sm" />
        <Row>
          <Column>
            <Input {...register("latitude")} placeholder={t("form.latitude")} />
          </Column>
          <Column>
            <Input
              {...register("longitude")}
              placeholder={t("form.longitude")}
            />
          </Column>
        </Row>
        <Spacing bottom="sm" />

        <Row>
          <Toggle
            value={visiblityCheck.toString()}
            checked={visiblityCheck}
            id="visible"
            name="visible"
            onChange={() => setVisiblityCheck(!visiblityCheck)}
          />
          <label htmlFor="visible">{t("form.visibileonmap")}</label>
        </Row>
        <Spacing bottom={"lg"} />
        <label>Media </label>
        <ModalGallery isMany onClick={handleGallery} />
        <Gallery medias={media} small />

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
